.box_main {
  margin: 10px 0 10px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.container_values {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

.button_remove {
  display: flex;
  align-items: flex-end;
}

.header-info {
  text-align: right;
  font-size: 20px;
  margin-top: 27px;
  margin-right: 10px;
  &.center-info {
    text-align: center;
  }
}

.radios-flipsignage {
  text-align: center;
  label {
    margin-right: 20px !important;
  }
}

.no-bottom-padding-col {
  padding-bottom: 0;
}

.no-top-margin {
  margin-top: 0 !important;
}

.flex-center {
  justify-content: center;
}

.radio-row {
  margin-bottom: 1.875rem;
}

.tranformation-header {
  justify-content: start;
  margin: 0;
  background-color: whitesmoke;
  padding: 4px 0 20px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dddddd;
}

.separator:not(:empty)::before {
  margin-right: 0.8em;
}

.separator:not(:empty)::after {
  margin-left: 0.8em;
}

.hightlight-column {
  font-style: italic;
  color: #ff9751;
  font-weight: bold;
}

.trash-float-button {
  position: absolute;
  top: 22px;
  right: -41px;
}

.conditions-row {
  padding: 0 15px;

  > s-col {
    padding: 0 10px 30px 0;
  }
}

.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;

  > button {
    display: contents;
  }
}

.tabs-position {
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
}

.button-menu {
  button {
    padding-left: 12px;
    padding-right: 12px;
    .fix-icon-position {
      margin-top: 8px;
    }
  }
}

.collapsed-header {
  position: absolute;
  top: 11px;
  left: 50%;
  transform: translateX(-50%);
}

.delete-button {
  color: var(--color-red-medium, #d92b2b) !important;
}

.button-concat {
  position: absolute;
  top: 18px;
  &.concat-first {
    left: -48px;
  }
  &.concat-second {
    left: -91px;
  }
}

.invisible {
  display: none;
}

.concat-preview-label {
  font-style: italic;
  color: #96999b;
  text-shadow: 0px 1px 1px white;
}

.small-info {
  font-size: small;
  color: #96999b;
}

.button-add-remove-column {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 34px;
  border-radius: 5px;
  font-size: 16px;
  margin: 5px;
  padding: 5px;
  color: var(---color-blue-dark, #0c7bab);
  border: 1px solid var(---color-blue-dark, #c4c4c4);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
  &:hover {
    background-color: var(---color-blue-dark, #0c7bab);
    color: white;
  }
  &.cancel {
    font-weight: 500;
    font-family: monospace;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: #efefef;
    color: #b3b3b3;
    border: 1px solid #b3b3b3;
  }

  &.add-column:hover {
    background-color: var(--color-green-dark, #22821d);
    color: white;
  }

  &.remove-column:hover {
    background-color: var(--color-red-dark, #c70000);
    color: white;
  }
}

.icon-delete:before {
  font-family: "s-icons", sans-serif;
  position: relative;
  font-size: 12px;
  content: "\e613";
}

s-select div[ref="select-input-container"] {
  background-color: white;
}

.fix-icon-position-default {
  margin-left: 5px;
  margin-right: 5px;
  top: 2px;
}
