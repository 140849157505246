.FileUploadContainer {
  position: relative;
  margin: 25px 0 15px;
  border: 2px dotted lightgray;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  .FormField {
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    height: unset;
    max-height: unset;
    &:focus {
      outline: none;
    }
  }

  .DragDropText {
    font-weight: 600;
    line-height: 30px;
    font-size: 18px;
    text-align: center;
  }

  .FileLimitsText {
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
  }

  .UploadFileBtn {
    border-radius: 4px;
    padding: 6px 18px;
    background: #fafafa;
    border: 1px solid #c4c4c4;
    margin-top: 28px;
  }

  .fix-loader-position {
    margin-right: 4px;
    margin-bottom: -2px;
  }
}
