.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.spinner {
  border: 4px solid #c4c4c4;
  border-radius: 50%;
  border-top: 4px solid #444444;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  &.small {
    border: 3px solid #e0e0e0;
    border-top: 3px solid #7f7f7f;
    width: 20px;
    height: 20px;
    animation: spin 0.6s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
