$transformationTypeButonColor: var(--color-blue-dark, #0c7bab);

.tabs-transformation-container {
  display: inline-flex;
  justify-content: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #f5f5f5;
  text-align: center;
  .tabs-transformation {
    list-style: none;
    display: flex;
    gap: 10px;
    height: 30px;
    align-items: center;
    li {
      position: relative;
      .button-background {
        position: absolute;
        border-radius: 5px;
        top: 3px;
        left: 0px;
        width: 100%;
        height: 30px;
        background-color: white;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: none;
        width: 150px;
        border: none;
        color: gray;
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
        .selected {
          color: $transformationTypeButonColor;
        }
      }
    }
  }
}
