.container_wapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_data {
    flex: 1;
  }

  &_actions {
    display: flex;
    justify-content: space-between;
  }

  .steeper-wrapper {
    overflow: hidden;
  }
}
