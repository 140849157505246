:root {
  --primary-orange: #ff6600;
  --secondary-orange: #e05200;

  --primary-blue: #059bd2;
}

.avalara_title {
  color: var(--primary-orange, #ff6600);
}

.avalara_divider {
  border-top: 1px solid #c4c4c4;
  padding-top: 20px;
  margin-top: 20px;
}

.editing-mode {
  font-size: 1.3em;
  color: #b6b6b6;
  margin-top: -22px;
  font-weight: 100;
  .value {
    transition: color 0.5s;
    &:hover {
      color: #79776f;
      cursor: pointer;
      border-bottom: 1px solid;
    }
  }
}

.remove_html {
  display: none;
}

.collapsible {
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.6s ease;
}

.collapsible.expanded {
  overflow: visible;
}

.required::after {
  color: #c70000 !important;
  content: "*";
  position: absolute;
  margin-left: 2px;
}

.no-wrap-big-screen {
  white-space: nowrap;
}

@media only screen and (min-width: 384px) and (max-width: 969px) {
  .no-wrap-big-screen {
    white-space: normal;
  }
}

@media only screen and (min-width: 384px) and (max-width: 599px) {
  .collapsible.expanded {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.collapsible-content {
  padding: 10px;
  background-color: #f1f1f1;
}

.collapsed {
  max-height: 50px;
}

.collapsible-header {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.collapsible-header-hidden {
  opacity: 0;
}

.expanded {
  max-height: 1000px; /* Ajuste este valor conforme necessário */
}

s-select ul > li:not([ref="nomatch"]).visually-focused {
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #a1a0a0;
}

s-select
  ul
  > li:not([ref="nomatch"]):not([ref="loading"]):not([ref="nomatch"]):not(
    [aria-disabled="true"]
  ):hover {
  background-color: #f2f6f9;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid #3e8fb3;
  outline: 0;
}

s-select div[ref="disclosure-icon"] {
  height: 2.1rem;
}

.s-table-container {
  border: 1px solid var(--color-gray-light, #c4c4c4);
  display: block;
  overflow: auto;
}

.s-table-container > table,
table.s-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}

.s-table-container > table tr,
table.s-table tr {
  border-radius: 0;
}

.s-table-container thead tr:first-of-type th {
  border-top: 0;
}
.s-table-container tr:last-of-type td,
.s-table-container tr:last-of-type th {
  border-bottom: 0;
}

.s-table-container > table td:not([class*="pad-"]),
.s-table-container > table th:not([class*="pad-"]),
table.s-table td:not([class*="pad-"]),
table.s-table th:not([class*="pad-"]) {
  padding-left: 0.75rem;
  padding-left: var(--padding-sm, 12px);
  padding-right: 0.75rem;
  padding-right: var(--padding-sm, 12px);
}

.s-table-container tbody {
  tr:hover {
    background-color: #f7fdff;
  }
}

.no-data-found {
  text-align: center;
  font-size: medium;
  padding: 20px;
  color: var(--color-gray-light, #c4c4c4);
  font-weight: 300;
}

a.button:focus,
button:focus,
s-table-container:focus,
s-tabs s-tab:focus {
  border: 1px solid #0c7bab;
  box-shadow: none;
  outline: none;
  outline-offset: 0px;
  z-index: 101;
}

s-icon[name="alert-triangle-filled"] {
  font-size: 40px;
  color: #ffd800;
  text-shadow: 0px 1px 1px gray;
}

s-alert [ref="dismiss-button"]:focus {
  box-shadow: none;
  border: none;
}

.input-validation-hidden {
  position: absolute;
  width: 100%;
  top: 28px;
  left: 30%;
  opacity: 0;
  height: 0;
}

.special-header-center {
  div[ref="header-wrapper"] {
    display: inline-flex;
    justify-content: center;
    margin-left: 25px;
  }
}

.error-page-message {
  font-size: large;
  color: #c20000;
  font-weight: bold;
  padding: 10px;
  background-color: #fff0f0;
  border-radius: 5px;
  border: #f57a7a solid 1px;
  text-align: center;
}

.with-80-percent {
  min-width: 80% !important;
  max-height: 80vh !important;
}
