.text_transform::first-letter {
  text-transform: uppercase;
}

#react-select-3-input {
  box-shadow: none;
}

#react-select-3-listbox {
  margin: 0;
}

.css-1dimb5e-singleValue {
  margin-top: 9px;
}

.css-qbdosj-Input {
  height: 28px !important;
  margin-top: 5px;
  border: none;

  input {
    border: none;
    box-shadow: none;
  }
}

.is-date-checkbox-container {
  padding-bottom: 10px;
}

.skylab-select-container {
  width: 100%;
  display: inline-block;
  display: relative;
}

.size-with-button {
  width: calc(100% - 40px);
}

.container-info-instractions {
  margin-top: 10px;
  .container {
    display: block;
  }
  ul {
    margin-top: 10px;
    padding-left: 20px;
    list-style: none;
    li:before {
      content: "●";
      display: inline-block;
      width: 1.5em;
      margin-left: -1em;
    }
    li.success {
      color: green;
      list-style-type: none;
      &:before {
        content: "✓ ";
      }
    }
    li {
      transition: color 0.5s;
    }
    li.error {
      color: red;
      list-style-type: none;
      &:before {
        content: "✗ ";
      }
    }
  }
}

.button-transform-select {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 16px;
  margin: 5px;
  padding: 5px;
  color: var(---color-blue-dark, #0c7bab);
  border: 1px solid var(---color-blue-dark, #0c7bab);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
  &:hover {
    background-color: var(---color-blue-dark, #0c7bab);
    color: white;
  }
  &.cancel {
    font-weight: 500;
    font-family: monospace;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: #efefef;
    color: #b3b3b3;
    border: 1px solid #b3b3b3;
  }
}

.icon-undo:before {
  font-family: "s-icons", sans-serif;
  position: relative;
  top: 1px;
  font-size: 12px;
  content: "\e98e";
}

.wapper_items {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mandatory-highlight {
  font-weight: 700;
  color: var(--color-red-medium, #d92b2b);
}

.optional-highlight {
  font-weight: 700;
  color: var(--color-green-medium, #38a632);
}

.container_tooltip_desc {
  border-radius: 50%;
  color: white;
  background-color: #0c7bab;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container_radio {
  display: flex;
}

.container_tag {
  margin-top: 10px;
}

.container_popover {
  padding: 5px !important;
}

.table_popover {
  margin: 0;
  border-collapse: collapse;
  border-spacing: 0;
  &_th {
    color: #0c7bab;
    background-color: #fafafa;
  }

  tr td,
  tr th {
    text-align: center;
    padding: 2px 8px !important;
    border: 1px solid lightgray;
  }

  p {
    margin: 0;
  }
}

.heading-sm {
  border-bottom: 1px dashed whitesmoke;
  border-top: 1px dashed whitesmoke;
}
