$gray-darkest: #1a1a1a;

.import-files-component {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: $gray-darkest;

  p {
    margin: 0;
  }

  .radio-group {
    margin-bottom: 26px;
  }

  .template-select {
    width: 360px;
  }

  .sub-title {
    margin: 30px 0 16px 0;
  }

  .title {
    color: var(--primary-orange, #ff6600);
  }

  .radio-label {
    margin-top: 7px;
  }

  .uploader-container {
    margin: 57px 0 24px 0;
  }

  .alert-fix-position {
    margin-bottom: -30px;
  }

  .confirmation-message {
    border-radius: 4px;
    border: 1px solid;
    padding: 16px 19px;
    display: flex;
    width: 100%;
    margin-bottom: -30px;

    &.success-message {
      border-color: #9ee69a;

      s-icon {
        color: #22821d;
        font-size: 24px;
        margin-right: 12px;
      }
    }

    &.error-message {
      border-color: #f57a7a;

      s-icon {
        color: #c70000;
        font-size: 24px;
        margin-right: 12px;
      }
    }
  }
}

s-select ul {
  max-width: 60vw;
  max-height: 276px;
}

s-select div[ref="select-input-container"] {
  min-height: 36px;
  cursor: default;
}

.headers-preview {
  color: "#1a1a1a";
  font-style: "italic";
  margin-bottom: 15px;
}

.headers-preview-itens-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  max-height: 217px;
  overflow: auto;
  .itens {
    border: 1px solid lightgray;
    padding: 3px 5px;
    margin: 2px;
    border-radius: 5px;
  }
}
