#SchemaEditedInfo {
  .columns {
    display: flex;
    justify-content: space-between;
  }

  .column {
    width: 48%;
  }

  .columns::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: #ccc;
  }

  h3 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  section {
    margin-bottom: 20px;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 20px;
    background-color: whitesmoke;
  }

  p {
    font-size: 1em;
    margin: 0;
  }

  span {
    font-weight: bold;
  }

  .missing {
    color: red;
    font-weight: 600;
  }

  .added {
    color: green;
    font-weight: 600;
  }

  .isOptional {
    color: #c4c4c4;
  }

  .highlighted-list-content {
    overflow: hidden;
    transition: max-height 0.5s;
    box-sizing: border-box;
    .compared-list {
      font-weight: 400;
    }
  }

  .more-btn {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    border: 0;
    background-color: transparent;
    color: #0c7babab;
    font-weight: inherit;
    transition: color 0.5s;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #0c7bab;
    }
  }

  .gradient-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    transition: opacity 0.5s;
    opacity: 1;
    &.off {
      background-color: transparent;
      opacity: 0;
    }
  }
}
