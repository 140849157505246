.from-to-component {
  p {
    margin: 0;
  }

  .wapper_items {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      margin: 0 !important;
    }

    .item-name {
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
    }
  }

  .item-metadata {
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
  }

  .select-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
  }

  .display-linebreak {
    white-space: pre-line;
  }

  .container-helper {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    font-family: sans-serif;
    color: #979797;

    .header,
    .box {
      width: 50%;
    }

    .divider-left {
      border-color: rgb(240, 240, 240) !important;
    }

    .header {
      text-align: center;
      font-size: small;
      background-color: whitesmoke;
      text-shadow: 0px 1px 1px white;
    }

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      font-size: smaller;
      padding: 5px;
    }
  }
}

@media only screen and (min-width: 384px) and (max-width: 599px) {
  .hide-on-sm {
    display: none;
  }
}
